import "./styles.css";

import PartySocket from "partysocket";
import type { Connection, ChatRoom, ListResponse } from "src/server/common/types";

declare const PARTYKIT_HOST: string;

const lobbyRoom = 'lobby';

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const party = new URLSearchParams(window.location.search).get("party") ?? "main";
const room = new URLSearchParams(window.location.search).get("room") ?? (party === 'main' ? lobbyRoom : null);

const isChatRoom = party === 'chat';

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

const roomsDiv = document.createElement("div") as HTMLDivElement;
const messagesDiv = document.createElement("div") as HTMLDivElement;
const statusElement = document.createElement("div") as HTMLDivElement;
const roomElement = document.createElement("div") as HTMLDivElement;

if(!isChatRoom) {
    output.appendChild(document.createElement("h3")).innerText = "Rooms: ";
    output.appendChild(roomsDiv);
} else {
    output.appendChild(document.createElement("h3")).innerText = "Room: ";
    output.appendChild(roomElement);
    roomElement.innerHTML = room ?? 'No room specified';

    output.appendChild(document.createElement("h3")).innerText = "Status: ";
    output.appendChild(statusElement);
    output.appendChild(document.createElement("h3")).innerText = "Messages: ";
    output.appendChild(messagesDiv);
    addMessage(systemMessage(`Connected to ${party} server - room: ${room}`));
}

interface ChatMessage {
    id: string,
    cid: string,
    role: string,
    content: string
}

function systemMessage(content: string) {
    return {
        id: 'system',
        cid: 'system',
        role: 'system',
        content: content
    }
}

// Helper function to add a new line to the DOM
function addMessage(message: ChatMessage) {

    const text = `${message.role}: ${message.content}`

    messagesDiv.appendChild(document.createTextNode(text));
    messagesDiv.appendChild(document.createElement("br"));
}

function updateMessages(messages: ChatMessage[]) {
    messagesDiv.innerText = messages.map(message => `${message.role}: ${message.content}`).join('\n');
}

function updateStatus(status: string) {
    statusElement.innerText = status;
}

function updateRooms(rooms: ChatRoom[]) {
    console.log(rooms);
    if(!rooms) return;
    //loop through rooms and update the DOM key is the room id, value is the number of rooms
    roomsDiv.innerHTML = ''; // Clear existing content
    roomsDiv.innerHTML += `<h4>Total: ${Object.keys(rooms).length}</h4>`;
    roomsDiv.innerHTML += `<hr><br>`;
    for (const [key, room] of Object.entries(rooms)) {
        const roomDiv = document.createElement("div");
        roomDiv.id = key;
        roomDiv.innerHTML += outputRoom(room);
        roomsDiv.appendChild(roomDiv); // Append to roomsDiv, not roomDiv
    }
}

function outputRoom(room: ChatRoom) {
    if(!room) return;

    let output = `<h4>Chatbot ID: ${room.chatbotId}</h4>`;

    output += `<h4>Room ID: ${room.id}`;
    if(!isChatRoom) {
        output += `&nbsp;<a target="_blank" href="${new URL(window.location.href).origin}/?party=chat&room=${room.id}">Link</a>`;
    }
    output += `</h4>`;

    output += `<h4>Total Connections: ${room.totalConnections}</h4>`;
    if(!isChatRoom) {
        output += `<h4>Connections: </h4>`;
        output += `<ul>`;
        room.connections?.forEach((connection: Connection) => {
            output += `<li>ID: ${connection.id}</li>`;
            output += `<li>SID: ${connection.session_id}</li>`;
            if(connection.chat_user_id) {
                output += `<li>UID: ${connection.chat_user_id}</li>`;
            }
        });
    }
    output += `</ul>`;

    if(!isChatRoom) {
        output += `<br><hr><br>`;
    }
    return output;
}

function updateUI(payload: any) {
    console.log(payload);
    if(isChatRoom) {
        if (payload.type === 'message') {
            addMessage(payload.data);
        } else if (payload.type === 'messages') {
            updateMessages(payload.data);
        } else if (payload.type === 'room') {
            roomElement.innerHTML = outputRoom(payload.data) ?? '';
        }
    }
}

if(!room) {
    addMessage(systemMessage(`No room specified`));
    throw new Error(`No room specified`);
}

const conn = new PartySocket({
    host: PARTYKIT_HOST,
    room: room,
    party: party,
});


// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
    const payload = JSON.parse(event.data);
    updateUI(payload);
});

// Let's listen for when the connection opens
conn.addEventListener("open", async () => {
    updateStatus('Connected');
    conn.close()
    if(!isChatRoom) {
        const result = await fetch(`/parties/main/${lobbyRoom}`)
        const payload = await result.json() as ListResponse<ChatRoom[]>;
        updateRooms(payload.items);
    }else{
        const result = await fetch(`/parties/chat/${room}/messages`)
        const payload = await result.json() as ListResponse<ChatMessage[]>;
        updateUI({
            type: 'messages',
            data: payload.items
        });

        const roomResult = await fetch(`/parties/main/${lobbyRoom}?room=${room}`)
        const roomPayload = await roomResult.json() as ListResponse<ChatRoom[]>;
        updateUI(roomPayload.items);
    }
});

conn.addEventListener("close", () => {
    updateStatus('Disconnected');
});
